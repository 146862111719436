import styled from "styled-components"
import theme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import { pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

const px14 = pxToRem(14)
const px595 = pxToRem(595)

export const InvitationCenterWrapper = styled.div`
  background-color: ${theme.colors.overLight.white};
  border: solid 1px ${theme.colors.overLight.white90};
  border-top: none;
  border-radius: 0 0 0.5rem 0.5rem;

  .invite__text--title {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: ${theme.fonts.weights.bold};
    text-transform: uppercase;
    color: ${theme.colors.overLight.white20};
  }

  .invite__text--subtitle {
    font-size: 1rem;
    line-height: 1rem;
    font-weight: ${theme.fonts.weights.bold};
    letter-spacing: -0.1px;
    color: ${theme.colors.overLight.white20};
  }

  .invite__text--body1 {
    font-size: ${px14};
    line-height: 1.25rem;
    letter-spacing: -0.1px;
    font-weight: ${theme.fonts.weights.bold};
    color: ${theme.colors.overLight.white20};
  }

  .invite__text--body2 {
    font-size: ${px14};
    line-height: 1.25rem;
    letter-spacing: -0.1px;
    color: ${theme.colors.overLight.white40};}
  }

  .invite__text--body3 {
    font-size: 0.75rem;
    line-height: ${px14};
    letter-spacing: -0.1px;
    color: ${theme.colors.overLight.white20};
  }

  .invite__text--table-header {
    font-family: ${theme.fonts.family.condensed};
    font-weight: ${theme.fonts.weights.semireg};
    color: ${theme.colors.overLight.white50};
    font-size: 0.75rem;
    line-height: 0.75rem;
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }

  .invite__text--row-main {
    font-size: ${px14};
    line-height: 1rem;
    letter-spacing: -0.1px;
    font-weight: ${theme.fonts.weights.bold};
    color: ${theme.colors.overLight.white20};
  }

  .invite__text--centered {
    text-align: center;
  }

  .invite__title-box {
    padding: 1.25rem 1.5rem;
  }

  .invite__info-box {
    padding: 1.5rem;
    padding-top: 0;
  }
  
  .invite__actions-box {
    padding: 1.5rem;
    padding-top: 0;
    display: flex;
    alig-items:center;
  }

  .invite__pending-title-box {
    padding: 0 1.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .invite__invitations-table {
    .invitations__row{
      display: flex;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-bottom: 0.5rem;
      &:not(:last-of-type){
        border-bottom: solid 1px ${theme.colors.overLight.white90};
      }
    }

    .invitations__row--content {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    .invitations__row--no-content {
      padding-top: 2rem;
      padding-bottom: 2rem;
      max-width: ${px595};
      margin: 0 auto;

      .main-line {
        margin-bottom: 0.5rem;
      }
    }

    .invitations__col--3 {
      flex: 3;
    }

    .invitations__col--9 {
      flex: 9;
    }
  }
`
